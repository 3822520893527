import React from "react";
import {
  InfoOutlined as Info,
  LightbulbOutlined as Tip,
  ErrorOutlineOutlined as Important,
  WarningAmberOutlined as Caution,
  LocalFireDepartmentOutlined as Warning,
} from "@mui/icons-material";
import "./Note.style.scss";

interface INoteProps {
  type: "note" | "tip" | "important" | "caution" | "warning";
  children: any;
}

export default function Note({ type, children }: INoteProps) {
  let icon;
  let title;
  switch (type) {
    case "note":
      icon = <Info />;
      title = "Note";
      break;
    case "tip":
      icon = <Tip />;
      title = "Astuce";
      break;
    case "important":
      icon = <Important />;
      title = "Important";
      break;
    case "caution":
      icon = <Caution />;
      title = "Attention";
      break;
    case "warning":
      icon = <Warning />;
      title = "Danger";
      break;
  }

  return (
    <div className={`note-root ${type}`}>
      <div className="note-title">
        <span>{icon}</span>
        <span>{title}</span>
      </div>
      <p>{children}</p>
    </div>
  );
}
