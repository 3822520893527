import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import PageInfo from "../../components/PageInfo";
import Note from "../../components/Note";

import accesexport from "../../content/tutorial/images/acces-export.png";
import nouvelexport from "../../content/tutorial/images/nouvel-export.png";

export default function FeatExport() {
  return (
    <Layout title="Exporter l'étude">
      <div className="page-body">
        <PageInfo ARCCo AdmGl />
        <div className="page-title">
          <h1>Exporter l'étude</h1>
        </div>
        <section>
          <h2 id="accéder-à-l-export">Accéder à l'export</h2>
          <p>
            Une fois toutes les données de vos patients réunies, vous pouvez
            exporter les données afin de les visualiser, les traiter, les
            analyser. Pour se faire on se rend dans l'onglet <b>Analyse</b> puis{" "}
            <b>Exports</b>.
          </p>
          <img src={accesexport} alt="Onglet exports" className="centered" />
          <h2 id="exporter-des-données">Exporter des données</h2>
          <p>
            Cliquez sur <b>Nouvel export</b> afin de faire une demande d'export,
            celle-ci sera résolu dans les 5 minutes qui suivront votre demande.
          </p>
          <img src={nouvelexport} alt="Nouvel export" className="centered" />
          <Note type="note">
            <p>
              Si la demande d'export n'apparaît pas immédiatement, cliquez sur
              le bouton "<b>Rechargement</b>" en bas de la liste.
            </p>
          </Note>
        </section>
      </div>
    </Layout>
  );
}
