import React from "react";
import { AdminPanelSettings } from "@mui/icons-material";
import "./PageInfo.style.scss";

interface IPageInfoProps {
  TEC?: boolean;
  TECp?: boolean;
  ARC?: boolean;
  ARCCo?: boolean;
  Inv?: boolean;
  InvCo?: boolean;
  AdmLo?: boolean;
  AdmGl?: boolean;
}

export default function PageInfo({
  TEC,
  TECp,
  ARC,
  ARCCo,
  Inv,
  InvCo,
  AdmLo,
  AdmGl,
}: IPageInfoProps) {
  let roles = [
    { id: TEC, name: "TEC" },
    { id: TECp, name: "TEC+" },
    { id: ARC, name: "ARC" },
    { id: ARCCo, name: "ARC coordonnateur" },
    { id: Inv, name: "Investigateur" },
    { id: InvCo, name: "Investigateur coordonnateur" },
    { id: AdmLo, name: "Administrateur local" },
    { id: AdmGl, name: "Administrateur global" },
  ];

  let xroles: any = "Tous les rôles";
  if (roles.find((item) => item.id === true)) {
    let actRoles = roles.filter((item) => item.id === true);
    xroles = actRoles.map((role, index) => (
      <span>{(index ? ", " : "") + role.name}</span>
    ));
  }

  return (
    <div className="page-info-root">
      <div>
        <AdminPanelSettings />
        <p>
          <a href="/fr/guides/roles" target="_blank">
            <b>Rôle nécessaire</b>
          </a>
        </p>
      </div>
      <p>{xroles}</p>
    </div>
  );
}
